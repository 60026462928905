/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
    position: relative;
	z-index: 0;
	overflow: hidden; 

	& .intro__inner {
		position: absolute;
		top: 90px;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		
		& .shell { 
			width: 1170px;
		}
 
	} 

	& .intro__callout {
		float: right;
		min-width: 460px;
		max-width: 460px;
		min-height: 356px;
		background: rgba(255, 107, 0, 0.65); 
		text-align: center;
		padding: 49px 46px 38px 33px;
		text-transform: uppercase;
		color: $white;
		font-weight: 700;
        position: relative;

        & .intro__container {
        	padding-left: 46px;
        }

        &:after {
        	content: '';
        	width: 120px; 
        	height: 4px;
        	background: $orange;
        	position: absolute;
        	bottom: -4px;
        	right: 30px;
        }

		& small,
		& h3 {
			display: inline-block;
			// color: $blue;
		}

		& small {
			font-size: 40px;
			font-weight: 400;
		}

		& .tagliner {
			font-size: 30px;
			position: absolute;
			top: 154px;
			left: 18px;
			text-transform: none;
			font-weight: 700;
		}

		& h3 {
			font-size: 86px;
		    font-weight: 700;
		    margin-bottom: 20px;
		}

		& h1 {
			font-size: 65px;
			margin: 0;
		}

		& h2 {
			font-size: 35px;
			text-align: right;
			font-weight: 700;
			text-shadow: none;
			margin-bottom: 25px;
		}

		& .btn {
			text-shadow: none; 
			font-size: 18px;
		}
	}

	& .intro__form {
		color: $white;
		min-width: 555px;
		max-width: 555px;
		float: left;
		background-size: cover;
		background-position: bottom center;
		padding-bottom: 24px;
		box-shadow: 1px 1px 14px rgba(34, 69, 96, 0.36);
		background-color: $white;
		
		& .tooltip_alt {
			position: absolute;
			right: -20px;
			top: -3px;
		}

		& form {
			position: relative;
			&:after {
				content: '';
				width: 120px;
				height: 4px;
				background: $orange;
				position: absolute;
				top: 0;
				left: 30px;
			}
		}

		& label {
			color: $dark_gray;
		}

		& .form__head {
			text-align: center;
			padding: 15px 0;
			background: $orange;
			font-weight: 700;
            text-transform: uppercase;
            position: relative;
		}

		& .form__body {
			padding: 25px 30px;

			& .form__row {
				display: flex;
				align-items: center;
			}

			& .form-group-2of2 {
				flex: 1 1 74%;
			    padding-right: 25px;
			}

			& .form__row {
				margin-bottom: 30px;
			}
		}

		& .form__foot {
			text-align: center;
		}
	}
}

@media (max-width: 1160px) {
    .intro {
        & .intro__inner  {
            & .shell {
                width: 100%;
            }
        }
        & .intro__form,
        & .intro__callout {
            float: none;
            margin: auto;;
        }

        & .intro__callout {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .intro {
    	.intro__inner {
			top: 50%;
			transform: translate(-50%,-50%);
    	}

        .intro__form {
            min-width: 460px;
            max-width: 100%;

            & .form__body {
                & .form__row {
                    flex-direction: column;

                }

                & .form-group-2of2 {
                    padding-right: 0;
                }
            }
        }
    }
}

@media (max-width: 479px) {
    .intro {
    	& .intro__inner {
			top: 50%;
    	}

        .intro__form {
            min-width: 300px;
            max-width: 300px;

	    	& .tooltip_alt {
				display: none;
	    	}

			& .form__head {
				padding: 8px;
			}

			& .form__title {
				font-size: 16px;
			}
        }

        .intro__form {
        	& .form__body {
        		& .form__row {
        			margin-bottom: 10px;
        		}
        	}
        }
    }
}

@media(max-width: 320px){
    .intro {
        overflow: hidden;
    }
}