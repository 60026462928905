/* ------------------------------------------------------------ *\
	Plan
\* ------------------------------------------------------------ */

.plan {
    & .plan__head {
        margin-bottom: 30px;
    }

    & .plan__list-body {
        padding-top: 56px;
    }

    img + img {
        margin-top: 30px;
    }
    
    & .plan__list-content {
        padding: 0 26px 26px 36px;
        font-size: 18px;
        text-align: justify;
    }

    & .plan__title-main {
        font-size: 40px;
        font-weight: 700;
        color: $orange;
        margin-bottom: 44px;
    }

    & .plan__title {
        font-size: 22px;
        color: $black;
        font-weight: 500;
        margin-bottom: 15px;
        background: #fff1e8;
        text-transform: uppercase;
        padding: 10px 40px;
    }

    & .plan__subtitle {
        font-size: 16px;
        font-weight: 400;
        font-family: $font_second;
        text-align: justify;
    }

    & ul {
        counter-reset: plan-counter;
    }
    & li {
        counter-increment: plan-counter;
        position: relative;

        &+li {
            margin-top: 50px;
        }

        &:before {
            content: counter(plan-counter, decimal-leading-zero) " "; 
            font-size: 70px;
            font-weight: 700;
            position: absolute;
            height: 160px;
            width: 160px;
            line-height: 120px;
            text-align: center;
            background: url('images/circleCloud.png');
            color: #fff;
            border-radius: 50%;
            border: 20px solid #fdd5a6;
        }

        &:nth-child(odd) {
            padding-left: 136px;

            &:before {
                position: absolute;
                left: 0;
            }

            & .plan__image {
                padding-left: 30px;
            }

        }
        
        &:nth-child(even) {
            padding-right: 136px;
            &:before {
                position: absolute;
                right: 0;
            }
            
            .plan__iamge {
                padding-left: 30px;
            }

            & .plan__list-inner {
                padding: 16px 26px 26px 0;
            }

            & .plan__list-head {
                padding: 26px 26px 26px 20px
            }
        }
    }
}

.plan_alt {
    .plan__list-head {
        text-transform: uppercase;
        background: #fff1e8;
        padding: 26px 26px 26px 36px;
        font-size: 18px;
    }

    & .plan__list-inner {
        padding: 16px 26px 26px 26px;
    }
}

@media(max-width: 767px){
 .plan {
    & li {
        &:before {
            content: counter(plan-counter, decimal-leading-zero) " "; 
            font-size: 40px;
            font-weight: 700;
            position: absolute;
            height: 90px;
            width: 90px;
            line-height: 70px;
            text-align: center;
            background: url('images/circleCloud.png');
            background-size: cover;
            color: #fff;
            border-radius: 50%;
            border: 10px solid #fdd5a6;
            }

            &:nth-child(odd) {
                padding-left: 94px;
            }

            &:nth-child(even) {
                padding-right: 86px;
            }
        }

        & .plan__list-body {
            padding-top: 20px;
        }

        & .plan__title {
            font-size: 30px;
        }
   }
}