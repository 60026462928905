/* ------------------------------------------------------------ *\
	Navigations
\* ------------------------------------------------------------ */


.nav {
    font-weight: 500;
    color: #16598c;

	& ul {
		&:after {
			@extend .clearfix;
		}
	}

	& li {
		float: left;
		text-align: center;
		transition: ease-in-out .3s;
        cursor: pointer;
        height: 67px;
        position: relative;

        &:after {
        	content: '';
        	position: absolute;
        	bottom: 0;
        	left: 0;
        	width: 100%;
        	height: 3px;
        	background: $white;
        	opacity: 0;
        	visibility: hidden;
        	transition: ease-in-out .3s;
        }

        &:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
        }
	}

	& a {
		padding: 25px 15px;
		display: block;
		font-weight: 600;
	}

	& .active {
		&:after {
			content: '';
			opacity: 1;
			visibility: visible;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 3px;
			background: $white;
		}
		& a {
			color: $white;
		}
    }
    
    .logged {
        display: none;
        & a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 13px 20px;
        }
    }
}

.nav-lang {
	& ul {
		height: 67px;
		line-height: 75px;
		color: $orange;
		background: #fbc4a8;
		width: 60px;
		text-align: center;
		text-transform: uppercase;
		overflow: hidden;
		transition: height .3s;

		&.active {
			z-index: 999999;
			overflow: visible;
		}
	}

	& a {
		color: $orange;
		background: #fbc4a8;
		z-index: 1;
		position: relative;
		display: inline-block;
		width: 60px;
		margin: 0 -3px;
	}
}

.nav-trigger { 
	position: relative;
	z-index: 2;
	display: none;
	width: 40px;
	height: 26px;

	& span {
		position: absolute;
		display: block;
		width: 100%;
		height: 4px;
		background: $white;
		transition: top .2s .25s, left .2s .25s, opacity .2s .25s, -webkit-transform .2s 0s;
		transition: top .2s .25s, left .2s .25s, opacity .2s .25s, transform .2s 0s;
		transition: top .2s .25s, left .2s .25s, opacity .2s .25s, transform .2s 0s, -webkit-transform .2s 0s;
	
		&:nth-child(1){ top: 0; }

		&:nth-child(2){ top: 12px; }

		&:nth-child(3){ top: 25px; }
	}
}

.nav-trigger.active span { 
	transition: background .2s, top .2s, left .2s, opacity .2s, -webkit-transform .2s .25s;
	transition: background .2s, top .2s, left .2s, opacity .2s, transform .2s .25s;
	transition: background .2s, top .2s, left .2s, opacity .2s, transform .2s .25s, -webkit-transform .2s .25s; 
}

.nav-trigger.active span:nth-child(3),
.nav-trigger.active span:nth-child(1) { top: 12px; background: $white; }

.nav-trigger.active span:nth-child(2) { opacity: 0; }
.nav-trigger.active span:nth-child(1) { transform: rotate(45deg); }

.nav-trigger.active span:nth-child(3) { transform: rotate(-45deg); }


@media (max-width: 1200px) {
    .nav {
        & a {
            padding: 27px 10px;
        }
    }
}


@media (max-width: 1023px) {
    .nav {
		font-size: 18px;
		opacity: 0;
		visibility: hidden;
		transform: translateX(-100%);
		transition: ease-in-out .3s;
		position: absolute;
		display: flex;
		height: 100vh;
		width: 40%;
		top: 0;
		left: 0;
		flex-direction: column;
		background: rgba($orange, .79);
		justify-content: center;
		align-items: center;
		text-align: center;
        color: $orange;
        z-index: 10;
		& ul {
            flex-direction: column;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
		}

		& li {
			float: none;
			margin-left: 0;
			margin-bottom: 30px;
        }
        
        & a {
            padding: 10px 20px;
        }
	}

	.nav.active{
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}

	.nav-trigger{
        position: absolute;
        left: 15px;
		display: inline-block;
        z-index: 15;
        top: 24px;
    }
}

@media (max-width: 479px) {
    .nav {
        width: 100%;
    }
}