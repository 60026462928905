/* ------------------------------------------------------------ *\
    Atoms
\* ------------------------------------------------------------ */

.p0 {
    padding: 0;
}

.pb0 {
    padding-bottom: 0;
}

.mb0 {
    margin-bottom: 0;
}

.mb0-important {
    margin-bottom: 0 !important;
}

.mb30 {
    margin-bottom: 30px;
}

.mb35 {
    margin-bottom: 35px;
}

.pl30 {
    padding-left: 30px;
}

.pl15 {
    padding-left: 15px;
}

.pr30 {
    padding-right: 30px;
}

.pb50 {
    padding-bottom: 50px;
}

.mb45 {
    margin-bottom: 45px !important;
}

.mb30 {
    margin-bottom: 30px;
}

.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb50 {
    margin-bottom: 50px !important;
}

.mt10 {
    margin-top: 10px;
}

.m0-15 {
    margin: 0 15px;
}

.m0-8 {
    margin: 0 8px;
}

.tal {
    text-align: left;
}

.tac {
    text-align: center;
}

.row {
    display: flex;
    flex: 1;
}

.flex-column {
    flex-direction: column;
}

.flex-align-start {
    align-items: flex-start !important;
}

.flex-self-start {
    align-self: flex-start;
} 

.w100p {
    width: 100%;
}

@media(max-width: 767px) {
    .pl30,
    .pr30 {
        padding: 0;
    }
}