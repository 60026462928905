/* ------------------------------------------------------------ *\
	Form elements
\* ------------------------------------------------------------ */


.ui-timepicker-select {
    width: 100%;
    height: 34px;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    color: #999;
    padding: 0 34px;
    background: transparent;
}

select option:disabled {
  display: none;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $white;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0;
}

.help-block {
	position: absolute;
}

.field__icon {
    padding: 0 46px;
}

.field {
  cursor: pointer;
	font-weight: 500;
	color: #999;
	border: 1px solid $gray;
}

.textarea {
    width: 100%;
    font-family: inherit;
    resize: none;
    height: 140px;
    border-radius: 4px;
    padding: 10px;
    font-weight: inherit;
}

textarea.form-control {
    height: 180px;
    width: 100% !important;
    resize: none;
    padding: 10px;
    line-height: 1;
}

.field_alt {
    height: 40px;
    line-height: 40px;
}

.field_alt + .ui-timepicker-select {
    height: 40px;
}

.form__row {
    margin-bottom: 30px;
  
    // &:last-child {
    //   margin-bottom: 0;
    // }

    // &:last-of-type {
    //   margin-bottom: 0;
    // }
}

.form__row-alt {
    margin-bottom: 20px;
  
    &:last-child {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
}

.form__mandatory {
    color: $orange;
}

.cc-form {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;

    & span { 
        color: $orange;
    }  
}


.form__row-justified {
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label {
        position: relative;
        // padding-left: 1.95em;
        cursor: pointer;
    }

    /* radio aspect */
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before {
        content: '';
        position: absolute;
        right: 0; 
        top: 0;
        width: 24px; 
        height: 24px;
        border: 2px solid $orange;
        background: #fff;
        border-radius: 2px;
        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
    }

    /* checked mark aspect */
    [type="radio"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after {
        content: '✔';
        position: absolute;
        top: -2px;
        right: 3px;
        font-size: 28px;
        line-height: 0.8;
        color: $orange;
        transition: all .2s;
    }

    /* checked mark aspect changes */
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    /* disabled radio */
    [type="radio"]:disabled:not(:checked) + label:before,
    [type="radio"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
    }

    [type="radio"]:disabled:checked + label:after {
        color: #999;
    }

    [type="radio"]:disabled + label {
        color: #aaa;
    }

    /* accessibility */
    [type="radio"]:checked:focus + label:before,
    [type="radio"]:not(:checked):focus + label:before {
        opacity: .8;
    }
}


[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

[type="radio"]:not(:checked) + label.radioBox, 
[type="radio"]:checked + label.radioBox {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
}

/* radio aspect */
[type="radio"]:not(:checked) + label.radioBox:before,
[type="radio"]:checked + label.radioBox:before {
    content: '';
    position: absolute;
    left: 0; 
    top: 8px;
    width: 24px; 
    height: 24px;
    border: 2px solid $orange;
    background: #fff;
    border-radius: 2px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}

/* checked mark aspect */
[type="radio"]:not(:checked) + label.radioBox:after,
[type="radio"]:checked + label.radioBox:after {
    content: '✔';
    position: absolute;
    top: 6px;
    right: initial;
    left: 3px;
    font-size: 28px;
    line-height: 0.8;
    color: $orange;
    transition: all .2s;
}

/* checked mark aspect changes */
[type="radio"]:not(:checked) + label.radioBox:after {
    opacity: 0;
    transform: scale(0);
}

[type="radio"]:checked + label.radioBox:after {
    opacity: 1;
    transform: scale(1);
}

/* disabled radio */
[type="radio"]:disabled:not(:checked) + label.radioBox:before,
[type="radio"]:disabled:checked + label.radioBox:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}

[type="radio"]:disabled:checked + label.radioBox:after {
    color: #999;
}

[type="radio"]:disabled + label.radioBox {
    color: #aaa;
}

/* accessibility */
[type="radio"]:checked:focus + label.radioBox:before,
[type="radio"]:not(:checked):focus + label.radioBox:before {
    opacity: .8;
}

/* Base for label styling */


.label--radio {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
}

input[type="radio"], input[type="radio"] {
    margin: 0;
}


/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 24px; 
  height: 24px;
  border: 2px solid $orange;
  background: #fff;
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: -2px;
  left: 3px;
  font-size: 28px;
  line-height: 0.8;
  color: $orange;
  transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    opacity: .8;
}

[type="checkbox"]:not(:checked) + label.checkboxInv, 
[type="checkbox"]:checked + label.checkboxInv {
    padding: 0;
}

[type="checkbox"]:not(:checked) + label.checkboxInv:before,
[type="checkbox"]:checked + label.checkboxInv:before {
  right: 0;
  left: initial;
}
/* checked mark aspect */

[type="checkbox"]:not(:checked) + label.checkboxInv:after,
[type="checkbox"]:checked + label.checkboxInv:after { 
  right: 3px;
  left: initial;
}

label {
    & .label-addition {
        font-size: 14px;
        font-weight: 600;
        color: #8c8c8c;
    }
}

.label--radio {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
}

input[type="radio"], input[type="radio"] {
    margin: 0;
}

.radio {
    position: absolute;
    left: 0;
    margin: 0 1rem 0 0;
    cursor: pointer;
  }
  .radio:before {
    content: "";
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    position: absolute;
    top: 2px;
    left: 4px;
    z-index: 1;
    width: 12px;
    height: 12px;
    background: $blue;
    border-radius: 50%;
  }
  .radio:checked:before {
    transform: scale(1, 1);
  }

  .radio:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 2px solid $blue;
    border-radius: 50%;
  }

.form__icon-arrow {
    color: #2994e6;
    right: 14px;
    left: initial;
    height: 100%;
    top: 0;
    border-left: 1px solid $gray;
    padding-left: 11px;
    display: flex;
    position: absolute;
    z-index: 99;
    align-items: center;
    pointer-events: none;

    & i {
      color: $gray;
    }
}

#form_factura {
    & .form__foot {
        text-align: center;
    }
}

.info-container {
    padding: 30px 30px;
    border: 1px solid $gray;
} 

.ui-timepicker-wrapper {
    max-width: 100%;
    min-width: 100%;

    & .ui-timepicker-list {
        width: 100%;
    }
}

.c-dropdown {
    position: relative;
    display: inline-block;
    color: $orange;    
    text-align: left;
    z-index: 10;
    background: #f9c3a7;
  }
  
  .c-dropdown__list {
    margin: 5px 0 0 0;
    list-style: none;
    position: absolute;
    top: 125%;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    border-radius: 3px;
    background: $orange;
    transition: opacity .25s linear, top .25s linear;
    .c-dropdown.is-open & {
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }
  
  .c-dropdown__item {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color .25s linear;
    &:hover {
      background-color: $white;
      color: $orange;
    }
  }
  
  /**
   * Component: Button
   * --------------------------------------------------
   */
  
  .c-button {
    font-size: 18px;
    font-weight: 500;
    -webkit-appearance: none;
    overflow: visible;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    user-select: none;
    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
    }
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  
  /**
   * Component: Button
   * Modifier: Dropdown
   * --------------------------------------------------
   */
  
  .c-button--dropdown {
    padding: 30px 36px 29px 16px;
    font-size: 16px;
    line-height: 21px;
    min-height: 100%;
    transition: background-color .2s linear;
    position: relative;;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: $orange;
    }
  }
  

  
  /* Base styles for the element that has a tooltip */
  [data-tooltip],
  .tooltip {
    position: relative;
    cursor: pointer;
  }

  /* Base styles for the entire tooltip */
  [data-tooltip]:before,
  [data-tooltip]:after,
  .tooltip:before,
  .tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: 
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition:    
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition:         
      opacity 0.2s ease-in-out,
      visibility 0.2s ease-in-out,
      transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform:    translate3d(0, 0, 0);
    transform:         translate3d(0, 0, 0);
    pointer-events: none;
  }

  /* Show the entire tooltip on hover and focus */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after,
  .tooltip:hover:before,
  .tooltip:hover:after,
  .tooltip:focus:before,
  .tooltip:focus:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  /* Base styles for the tooltip's directional arrow */
  .tooltip:before,
  [data-tooltip]:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
  }

  /* Base styles for the tooltip's content area */
  .tooltip:after,
  [data-tooltip]:after {
    z-index: 1000;
    background-color: #f9e8de;
    color: #000;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
    padding: 16px 13px;
    width: 426px;
    border: 1px solid #ecd1bf;
    border-radius: 5px;
  }

  /* Directions */

  /* Top (default) */
  [data-tooltip]:before,
  [data-tooltip]:after,
  .tooltip:before,
  .tooltip:after,
  .tooltip-top:before,
  .tooltip-top:after {
    bottom: 100%;
    right: -30px;
  }

  [data-tooltip]:before,
  .tooltip:before,
  .tooltip-top:before {
    margin-left: -6px;
    margin-bottom: -12px;
    border-top-color: #000;
    border-top-color: #e9f4fc;
  }

  /* Horizontally align top/bottom tooltips */
  [data-tooltip]:after,
  .tooltip:after,
  .tooltip-top:after {
    margin-left: -80px;
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after,
  .tooltip:hover:before,
  .tooltip:hover:after,
  .tooltip:focus:before,
  .tooltip:focus:after,
  .tooltip-top:hover:before,
  .tooltip-top:hover:after,
  .tooltip-top:focus:before,
  .tooltip-top:focus:after {
    -webkit-transform: translateY(-5px);
    -moz-transform:    translateY(-5px);
    transform:         translateY(-5px); 
  }

  /* Left */
  .tooltip-left:before,
  .tooltip-left:after {
    right: 100%;
    bottom: 50%;
    left: auto;
  }

  .tooltip-left:before {
    margin-left: 0;
    margin-right: -12px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-left-color: #000;
    border-left-color: hsla(0, 0%, 20%, 0.9);
  }

  .tooltip-left:hover:before,
  .tooltip-left:hover:after,
  .tooltip-left:focus:before,
  .tooltip-left:focus:after {
    -webkit-transform: translateX(-12px);
    -moz-transform:    translateX(-12px);
    transform:         translateX(-12px); 
  }

  /* Bottom */
  .tooltip-bottom:before,
  .tooltip-bottom:after {
    top: 100%;
    bottom: auto;
    left: 50%;
  }

  .tooltip-bottom:before {
    margin-top: -12px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-bottom-color: #000;
    border-bottom-color: hsla(0, 0%, 20%, 0.9);
  }

  .tooltip-bottom:hover:before,
  .tooltip-bottom:hover:after,
  .tooltip-bottom:focus:before,
  .tooltip-bottom:focus:after {
    -webkit-transform: translateY(12px);
    -moz-transform:    translateY(12px);
    transform:         translateY(12px); 
  }

  /* Right */
  .tooltip-right:before,
  .tooltip-right:after {
    bottom: 50%;
    left: 100%;
  }

  .tooltip-right:before {
    margin-bottom: 0;
    margin-left: -12px;
    border-top-color: transparent;
    border-right-color: #000;
    border-right-color: hsla(0, 0%, 20%, 0.9);
  }

  .tooltip-right:hover:before,
  .tooltip-right:hover:after,
  .tooltip-right:focus:before,
  .tooltip-right:focus:after {
    -webkit-transform: translateX(12px);
    -moz-transform:    translateX(12px);
    transform:         translateX(12px); 
  }

  /* Move directional arrows down a bit for left/right tooltips */
  .tooltip-left:before,
  .tooltip-right:before {
    top: 3px;
  }

  /* Vertically center tooltip content for left/right tooltips */
  .tooltip-left:after,
  .tooltip-right:after {
    margin-left: 0;
    margin-bottom: -16px;
  }

  .tooltip-top:before {
    right: 7px;
  }

  .tooltip_alt {
    color: $orange;
    font-size: 20px;
  }

@media(max-width: 767px){
    .field {
        font-size: 16px;
    }

    .form-group {
        label {
            font-size: 14px;
        }
    }
}

@media(max-width: 479px) {
    .field__icon {
        padding: 0 4px 0 35px;
    }
}

@media(max-width: 320px) {
    .form-group label {
        font-size: 12px;
    }
}