/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form-group-inner,
.form__control {
	position: relative;
}

.form-group {
    position: relative;
	margin-bottom: 0;
} 

.form__icon {
	position: absolute;
	left: 12px;
	top: 7px;
	line-height: 0;
    pointer-events: none;
}

.form__control {
    & .form__icon {
        top: 50%;
        transform: translateY(-50%);
    }
}


.form-wrapper-border {
    border: 1px solid $gray;
}

// #form_details {
//     & .form__head {
//         margin-left: -25px;
//     }
// }

// #form_info {
//     & .form__head {
//         margin-right: -25px;
//     }
// }

.form-login {
    & .form__head {
        margin-bottom: 21px; 
        background: #fff1e8;
        padding: 15px 0 15px 22px;
        text-align: center;
        text-transform: uppercase;
    }

    & .form__title {
        font-size: 18px;
        font-weight: 600;
        color: $dark_gray;
    }

    & .btn {
        margin-bottom: 10px;
    }

    .form__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }   
}

.form__head-alt {
    margin-bottom: 21px;
    background: #fff1e8;
    padding: 15px 0 15px 22px;
    text-align: center;
    text-transform: uppercase;
}

.form__title-sec {
    font-size: 18px;
    font-weight: 600;
    color: $dark_gray;
}

.form_login_hidden {
    display: none;
}

#form_factura,
#cardDetails {
    display: none;
}

.active {
    display: block ;
}

.form-group-inner + .form-group-inner {
    margin-top: 18px;
}

.form-white {
    background: $white;
    border: 1px solid $blue;
    color: #333;

    &:after {
        @extend .clearfix;
    }

    & .form__contacts {
        width: 70%;
        float: left;
        padding-right: 27px;

        & .form__body {
            padding: 30px 0 30px 30px;
        }
    }

    & .form__head {
        background: #e9f4fc;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 0;
    }

    & .form__title {
        font-size: 18px;
        font-weight: 400;
    }

    & .aside .form__body {
        margin-top: 32px;
        padding-right: 70px;
    }
}

.form__row-solid_double {
    display: flex;
    justify-content: space-around;
}

.form_row_center {
    display: flex;
    justify-content: center; 
}

.form_background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    & .form__head {
        background: $blue;
        color: $white;
        text-align: center;
        padding: 20px 0;
        position: relative;
        text-transform: uppercase;

        &:after {
            content: '';
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            background: #2994e6;
            height: 19px;
            width: 37px;
            border-bottom-left-radius: 90px;
            border-bottom-right-radius: 90px;
        }
    }
    
    & .form__selects {
        padding: 14px 0 39px;
    }

    & .form__row-solid {
        background: #fff1e8;
        color: $dark_gray;
        padding: 14px 0 14px 30px;
        text-transform: uppercase;
        margin-bottom: 31px;

        & .form_row-title {
            font-size: 18px;
            font-weight: 600;
        }
    }

    & .form__row-solid_alt {
        color: #eee;
    }

    & .form__rows-body {
        padding: 0 30px;
    }

    & .form__content {
        font-weight: 600;
    }

    & .form__row-total-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        background: $orange_alt;
        padding: 0 10px;
    }

    & .form__row-total {
        margin: 0 -30px 38px;
        padding: 0;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 30px;
        color: $white;

        &.active {
            display: flex;
        }

        & .total__heading {
            font-weight: 600;
        }
    }
}

.forms {
    margin-top: 50px;
}

.form__row-justified {
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    align-items: center;

    .form__input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & label {
            width: 100%;
        }

        & .form__img {
            width: 24px;
            margin-right: 5px;
        }
    }

    // &:last-child {
    //     margin-bottom: 0;
    // }
}

.form_line {
    position: relative;

    &:after {
        content: '';
        width: 120px;
        height: 4px;
        background: $orange;
        position: absolute;
        top: -4px;
        left: 30px;
    }
}

.form_bordered {
    border: 1px solid $gray;
    padding-bottom: 45px;
    border-radius: 5px;
    position: relative;

    & .form__foot {
        text-align: center;
    }

    & .form__wrapper {
        max-width: 550px;
        margin: auto;
    }
}

.form__inner {
    padding: 30px 25px;
}

.form__row-center {
    text-align: center;
}

.form_background-alt {
    background-size: contain;
} 

.payment-form {
    padding: 35px 27px;

    & .list__item {
        margin-bottom: 30px;
    }
    
    & .payment__body_inner {
        padding: 18px 30px;
        border: 1px solid #ccc;
        position: relative;
        border-radius: 5px;
    }
    
    & .payment__body_bordered {
        padding-top: 15px;
        position: relative;
    }

    & .payment__methods {
        margin: 0 20px;

        & i {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    #card__method,
    #paypal__method-body {
        display: none;
    }
}

.form-footer-double {
    max-width: 595px;
    margin: auto;
}

.triangle-container {
    position: absolute;
    border-top: 3px solid $white;
    width: 16px;
    height: 1px;
    top: -2px;
    z-index: 99999;
    left: 71px;

    & .triangle__body {
        position: absolute;
        top: -8px;
        left: 2px;
        right: 0;
        width: 13px;
        height: 12px;
        transform: rotate(225deg);
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
}

#reviews {
    max-width: 682px;

    & .form__head {
        margin-bottom: 50px;
    }
}

.form__title-alt {
    color: $blue;
}

.form__wrapper.form_bordered {
    padding: 30px;
}

@media (max-width: 1023px) {
    .form_bordered {
        & .col,
        & .col_1of2 {
            width: 100%;
            float: none;
        }
    }
}

@media (max-width: 767px) {
    .form__head-full {
        margin: 0 -15px 21px;
    }

    .form__inner {
        padding: 30px 0;
    }
    
    .form-login {
        & .form__body,
        & .form__foot {
            padding: 0 15px;
        }

        & .form__head { 
            margin: 0 -15px 21px;
        }
    }

    .form__selects {
        & .form-group {
            margin-bottom: 20px;
        }
    }

    .intro {
        .intro__form {
            & .form__body {
                padding: 25px 10px 0;
            }

            & .form-group {
                margin-bottom: 12px;
                width: 100%;
            }
        }
    }

    .form_background {
        overflow: hidden;
        
        &.form_bordered {
            padding-bottom: 0;
        }

        & .form__rows-body {
            padding: 20px 0;

            & .form__row {
                margin-bottom: 0;
            }
        } 

        & .form__content {
            text-align: right;
            max-width: 85px;
            max-width: 155px;
        }

        & .form__input {
            max-width: 100%;
        }

        & .form__row-total {
            margin: 0 -19px;
            padding: 25px 13px 17px;
            font-size: 24px;

            & .total__heading {
                font-size: inherit;
            }
        }

        & .form__row-total-inner {
            background: $white;
            color: $orange;
        }
    }

    .form-white {
        & .form__contacts {
            float: none;
            width: 100%;
            padding-right: 0;

            & .form__body {
                padding: 25px;
            }
        }

        & .aside {
            & .form__body {
                padding: 10px 30px;
            }
        }
    }

    #form_info {
        & .form__head {
            margin: 0 -25px 21px;
            padding: 15px 14px;
        }
    }
}