/* ------------------------------------------------------------ *\
    Accordion
\* ------------------------------------------------------------ */

.accordions-wrapper {
    padding: 50px 0;
}

.accordions {
    & .accordions__head {
        text-align: center;
        margin-bottom: 30px;
        
        & .accordion__title {
            color: $orange;
            font-size: 21px;
            font-weight: 600;
            display: inline-block;    
        }
    }
}

.accordion {
    padding: 6px 0;
    overflow:hidden;
    position:relative;
    width:960px;
}

.accordion__custom {
    text-align: left;
    padding-bottom: 18px;

    & .accordion__head {
        padding: 5px 20px 5px 50px;
        position: relative;
    }

    & .accordion__title {
        margin-bottom: 0;
		cursor: pointer;
        display: inline-block;
        font-weight: 600;
        font-size: 16px;
        color: $dark_gray;
        transition: ease-in-out .3s;

        &.active {
            color: $orange;
        }
    }

    & .accordion__item {
        text-align: left;
        margin-bottom: 20px;
    }

    & .accordion__body {
        display: none;
        padding: 16px 30px 16px 30px;
        text-align: justify;
    }

    & a {
        color: inherit;
        display: block;
    }

    & li {
        color: $black;
    }

    & .current {
        color: $blue;
        background: #e6e8ed;
    }

    & .accordion__icon {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        position: absolute;
        display: inline-block;
        margin-right: 16px;
        background: transparent;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        border: 1px solid $orange;

        & span:nth-child(1),
        & span:nth-child(2) {
            position: absolute;
            background: $orange;
            transition: ease-in-out .2s;
            top: 50%;
        }
        
        & span:nth-child(1) {
            width: 12px;
            height: 2px;
            left: 50%;
            transform: translate(-50%,-50%)
        }

        & span:nth-child(2) {
            position: absolute;
            width: 2px;
            left: 50%;
            height: 12px;
            transform: translate(-50%,-50%);
        }
    }

    & .accordion__icon.active {
        & span:nth-child(2) {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
}

.accordion_alt {
    & .accordion__head {
        background: #8b8d97;        
        padding: 10px 25px;
    }

    & .accordion__title {
        color: $white;
        text-transform: uppercase;
        font-size: 18px;
    }

    & .accordion__item {
        margin-bottom: 30px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1023px) {
    .accordions { 
        & .accordions__head {
            & .accordion__title {
                display: block;
                text-align: center;

                &:after {
                    width: 100%;
                }
            }
        }
    }
    .accordions-wrapper {
        .col_1of2 {
            width: 100%;
        }
    }
}