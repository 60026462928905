/* ------------------------------------------------------------ *\
    404 page
\* ------------------------------------------------------------ */

.error_page {
    font-family: $font_second;
    border-radius: 4px;
    border: 1px solid $gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    & .error__head,
    & .error__footer {
        background: $orange;
        padding: 22px 0;
        text-align: center;
        color: $white;
        min-height: 65px;
    }

    & .error__subtitle {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    & .error__title {
        font-size: 207px;
        font-weight: 700;
        color: $orange;
    }

    & .error__details {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 35px;
        line-height: 1.6;
    }

    & .error__body {
        max-width: 596px;
        margin: auto;
        text-align: center;
        padding: 140px 0 235px;
    }
}