/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
    background: #53575f;
    padding: 70px 0 30px;
    border-top: 2px solid $orange;
    color: $white;

    & .footer__body {
        margin-bottom: 40px;
        overflow: hidden;
    }

    & .cols {
        margin: 0 -56px;
    }

    & .col {
        padding: 0 56px;
    }

    & .footer__logo {
        display: block;
        margin-bottom: 45px;
    }
 
    & .copyright {
        float: left;
    }

    & .credits {
        float: right;
    }

    & .payments {
        margin-top: 25px;
    }

    & .terms {
        display: block;
        margin-bottom: 59px;
        text-align: right;
        text-transform: uppercase;

        &:hover {
            color: $orange;
        }
    }
}

@media (max-width: 767px) {
    .footer {
        .footer__logo {
            text-align: center;
        }

        & .copyright {
            float: none;
            text-align: center;
        }

        & .terms,
        & .payments,
        & .credits {
            float: none;
            text-align: center;
            margin: 10px 0;
        }

        & .footer__confirm {
            text-align: center;
            display: block;
            margin-bottom: 35px;
        }
    }
}