/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {
	& span {
		font-weight: 400;
		font-size: 15px;
		text-transform: none;
        display: block;
	}

    & + & {
        margin-top: 60px;
    }

    & table {
        border: 1px solid $orange;

		tr {
			align-items: center;
			display: flex;
			padding: 15px 26px;
            border-bottom: 1px solid $white;

            &:nth-child(odd) {
                background: #ffeee3;
            }

            &:nth-child(even) {
                background: #f8f8f8;
            }

            &:first-child {
                // color: $white;
                background: linear-gradient(to right, #ed3e00 0%,#fc9700 100%);
            }

            &:last-child {
                border-bottom: 1px solid $white;
            }
		}

		& td:nth-child(odd) {
			width: 40%;
            text-transform: uppercase;
		}
		& td:nth-child(even) {
			width: 60%;
		}
	}
}

.table_data {
    font-size: 14px;
    margin: 0 -30px;
    display: none;
    
    & .btn {
        min-width: 100%;
        font-size: 12px;
        padding: 0;
        height: 32px;
        line-height: 32px;
        margin: 0 -10px;
    }

    & table {
        // table-layout: fixed;
    }

    & tr:nth-child(odd) {
        background: #f7f7f7;
    }

    & tr:nth-child(even) {
        background: $white;
    }

    & thead {
        background: #edeaea;
        font-weight: 600;
        text-align: left;
        border-bottom: 2px solid $white;

        & th {
            height: 48px;
            line-height: 48px;
            padding: 0 10px;
        }
    }

    & td {
        height: 48px;
        line-height: 48px;
        padding: 0 7px;
    }

    & #table_id {
        width: 1.5%;
    }

    & #table_name {
        width: 4%;
    }

    & #table_reservation,
    & #table_arrival,
    & #table_departure {
        width: 7%;
    }

    & #table_days {
        width: 2%;
        line-height: 1.6;
    }

    & #table_status {
        width: 2%;
    }

    & #table_gallery {
        width: 4.5%;
    }

    & #table_tax {
        width: 3%;
    }

    & #table_empty {
        width: 2.5%;
    }
}

.table-custom {
    .table__head {
        text-align: center;
        background: #fff1e8;
        padding: 15px 0;
    }

    .table__title {
        text-transform: uppercase;
        color: #333;
        font-size: 18px;
    }

    tr {
        justify-content: space-between;
    }

    & thead {
        & tr {
            &:first-child {
                background: #f7f7f7;
            }
        }
    }

    & table {
        font-weight: 400;
        color: #333;
        border: none;
        font-size: 16px;
        text-transform: none;
        & th {
            font-weight: 400;

        }

        & tbody {
            & tr {
                border: none;
                &:first-child {
                    border-top: 2px solid $white;
                    & th {
                        font-weight: 500;
                    }
                }

                &:nth-child(even){
                    background: #f7f7f7;
                }

                &:nth-child(odd){
                    background: #fff;
                }

                &:last-child{
                    background: #f7f7f7;
                    & th {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.checkout-table {
    background: $gray_alt;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.23);
    padding: 15px 20px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
 
    .checkout__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .checkout__body {
        padding-left: 15px;
    }

    & .checkout__row-multiline {
        flex-direction: column;
        align-items: flex-start;
    }

    & ul {
        padding-left: 20px;

        & li {
            & + li {
                margin-top: 15px;
            }
        }

        & i {
            margin-right: 7px;
        }
    }

    & .checkout__head {
        border-bottom: 1px solid $orange;
        padding-bottom: 10px;
        margin-bottom: 15px;
    }

    & .checkout__footer {
        border-top: 1px solid $orange;
        padding-top: 10px;
        margin: 0;
    }

    & .checkout__title, 
    & .checkout__total {
        color: $black;
    }

    & .checkout__title_alt,
    & .checkout__total_alt {
        color: $orange;
    }

    & .checkout__mod {
        color: $red_alt;
        font-size: 13px;
    }
    
    & .checkout__options {
        cursor: pointer;


        & i {
            font-size: 10px;
            color: $orange;
        }
    }

    .checkout__options-list {
        display: none;
        width: 100%;
        background: white;
        padding: 15px;
        font-size: 12px;
        color: black;
        box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    }
}

@media(max-width: 767px){
    .table-custom {
        & table {
            font-size: 13px;

            & tbody,
            & thead {
                td:nth-child(1),
                th:nth-child(1) {
                    width: 22% !important;
                }

                td:nth-child(even) {
                    padding-left: 0;
                }

                td:nth-child(2),
                th:nth-child(2) {
                    width: 32% !important;
                }

                td:nth-child(3),
                th:nth-child(3) {
                    width: 14% !important;
                }

                td:nth-child(4),
                th:nth-child(4) {
                    text-align: center;
                    width: 17% !important;
                }
            }
        } 
    }
}

@media(max-width: 479px){
    .table {
        & table {
            & td:nth-child(even) {
                padding-left: 30px;
            }

            & tr {
                padding: 15px 10px;
            }
        }
    }
}