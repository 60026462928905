/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */

[class^="ico-"] {
	background-image: url(images/sprite.png);
	display: inline-block;
	vertical-align: middle;
}


.ico-login {
	background-position: -11px -10px;
	width: 26px;
	height: 22px;
}

.ico-calendar {
	background-position: -18px -332px;
	width: 19px;
	height: 17px;
}


.ico-time {
	background-position: -47px -332px;
	width: 17px;
	height: 17px;
}

.ico-visa {
    background-position: -11px -186px;
    width: 56px;
    height: 35px;
}

.ico-masterCard {
    background-position: -77px -186px;
    width: 56px;
    height: 35px;
}

.ico-amex {
    background-position: -142px -186px;
    width: 56px;
    height: 35px
}

.ico-paypal {
    background-position: -13px -238px;
	width: 51px;
	height: 32px;
} 

.ico-avatar {
    background-position: -56px -7px;
	width: 29px;
	height: 29px;
}

.ico-download {
	background-position:  -14px -293px;
	width: 24px;
	height: 24px;
}

.ico-next {
	background-position: -48px -293px;
	width: 24px;
	height: 24px;
}

.ico-logout {
	background-position: -101px -9px;
	width: 21px;
	height: 25px;
}

.ico-lock {
	background-position: -18px -363px;
	width: 20px;
	height: 25px;
}