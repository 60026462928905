/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	background: $orange;
	background-image: url(images/menu.jpg);
	background-size: cover;
	background-position: center;
	border-bottom: 3px solid $white;

	& .logo {
		float: left;
		position: relative;
		top: 14px;
	}

	& .nav {
		float: right;

		& a {
			color: $white;
		}
    }
    
    & .nav-lang {
        float: right;
    }
}

@media (max-width: 1023px) {
    .header {
        & .logo {
            left: 60px;
        }
    }
}

@media(max-width: 767px) {
    .header {
        display: block !important;

        & .phone {
            font-size: 26px;
            margin-top: 15px;
            
            & span {
                display: none;
            }
        }
    }
}

@media (max-width: 479px) {
    .header {
        & .logo {
            width: 50%;
            top: 18px;
        }
    }
}