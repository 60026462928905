/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

.phone {
    color: white;
    font-weight: 500;
    font-size: 18px;

    &:hover {
        opacity: .8;
        color: $white;
    }
}

.header {
    & .phone {
        float: right;
        margin-right: 20px;
        margin-top: 25px;
    }
}

.thankYou {
    & .thankYou__head {
        min-height: 294px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center; 
        flex-direction: column; 
        text-align: center;
        border-radius: 4px;
        margin-bottom: 35px;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.48);
    }

    .thankYou__scroll {
        display: none;
    }

    & .thankYou__title {
        font-weight: 700;
        text-transform: uppercase; 
        line-height: 1.4;
        font-size: 35px;
    }

    & .thankYou__subtitle {
        font-size: 18px;
        text-transform: uppercase;
    }

    & .thankYou__article {
        font-size: 16px;
        margin-bottom: 35px;
        padding: 0 15px;

        & .thankYou__article__title {
            font-size: 20px;
            margin-bottom: 10px;
        }

        & .thankYou__subtitle {
            text-align: center;
            font-weight: 600;
            margin-bottom: 35px;
        }

        & ol {
            counter-reset: my-awesome-counter;

            & li {
                counter-increment: my-awesome-counter;

                &:before {
                    content: counter(my-awesome-counter) ". ";
                }
                
                ul {
                    padding-left: 20px;
                }
            }
        }

        & ul {
            & li {
                position: relative;
                padding-left: 10px;

                &:before {
                    content: "";
                    width: 3px;
                    height: 3px;
                    background: $black;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    border-radius: 50%;
                }
            }
        }

        & ul,
        & ol {
            li {
                & + li {
                    margin-top: 5px;
                }
            }
        }
    }

    & .thankYou__check { 
        border-radius: 4px;
    }
}

.downloads {
    border: 1px solid $orange;
    border-radius: 4px;
    padding: 0 15px;
    color: $orange;
    font-size: 18px;
    font-weight: 700;

    & .download {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        
        & + .download {
            border-top: 1px solid $orange;
        }
    }

    & .download__link {
        color: $gray;

        &:hover {
            color: $orange;
        }
    }

    & .download__price {
        margin-left: 15px;
    }
}

.location {
    & ul {
        padding: 0 20px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;

        & i {
            margin-right: 5px;
            color: $orange;
        }
    }

    & .location__map {
        border: 1px solid $orange;
    }
}

.tablet_hidden {
    display: none;
}

@media (max-width: 1023px) {
    .hidden_mobile {
        display: none;
    }
    
    .tablet_hidden {
        display: block;
    }

    .thankYou  {
        & .thankYou__head {
            position: relative;
            height: 82vh;
        }

        & .thankYou__scroll {
            display: block;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
        }

        & .thankYou__title {
            padding: 0 20px;
        }

        & .thankYou__check {
            margin-bottom: 20px;
        }

        & .thankYou__content-instructions {
            padding: 0 30px;
        }

        .thankYou__content-center {
            padding: 0 20px;
        }
    }
}
.clearfix { 
	content: '';
	display: table;
	clear: both;
}

.alert {
    top: 50%;
    position: absolute;
    width: 50%;
    z-index: 999;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
}

.aside {
    float: left;
    width: 30%;
}

.sides {
	overflow: hidden;

	&:after {
		@extend .clearfix;
	}

	& .side__title {
		text-transform: uppercase;
		color: $blue;
		position: relative;
	}

	& .side {
		width: 50%;
		float: left;
        padding: 44px 0 50px;
        
        &:after {
            @extend .clearfix;
        }

		& .testimonials {
			padding-right: 60px;
		}
	}

	& .side_solid {
		background: #f6f6f6;
	}

	& .side__inner {
		float: right;
		min-width: 570px;
		max-width: 570px;
	}

	& .side_plain {
		min-width: 570px;
		max-width: 570px;
		float: left;
		position: relative;
		min-height: 540px;

		& .side__image {
			position: absolute;
			left: 30px;
			width: 130%;
		}

		& .side__title {
			position: absolute;
			bottom: 0;
			left: 30px;
			color: #292929;
			&:after {
				content: '';
				height: 1px;
				position: absolute;
				left: 320px;
				bottom: 25px;
				background: #000;
				width: 300%;
			}
		}
	}

	& .side__rating {
		display: flex;
		align-items: center;
		font-weight: 700;
		color: #333;
		position: relative;
		padding-bottom: 25px;
		margin-bottom: 30px;

		&:after {
			content: '';
			width: 200%;
			left: -100%;
			bottom: 0;
			height: 1px;
			background: $blue;
			position: absolute;
		}

		& .side__rating-title {
			font-size: 80px;
			line-height: 1;
		}

		& p {
			font-size: 27px;
			display: inline-block;
			line-height: 1.1;
		}
	}
}

.termsConditions {
    & label {
        font-weight: 400;
    }
}

.copyright {
    font-size: 14px;
}

.alignLeft {
	float: left;
}

.alignRight {
	float: right;
}

.ttu {
    text-transform: uppercase;
}

.ttc {
    text-transform: capitalize;
}

.link-blue {
    color: $orange;
}

.form-control-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial;
    height: 100%;
}

.has-error .textarea  {
    border: 1px solid $red;
}

.has-success .textarea  {
    border: 1px solid $green;
}

.forgotten__password {
    color: $orange;
    font-size: 17px;
    & a {
        text-decoration: underline;
    }
}


.info-controls {
    & .btn.active {
        color: $white;
        background: $orange;
        opacity: 1;
    }
}

.conditions {
        counter-reset: my-badass-counter;
        list-style-type: none;

    & .condition__title {
        font-size: 30px;
        color: $orange;
        margin-bottom: 30px;
    } 

    & a {
        color: $orange;
    }

    & p {
        color: $black;
        font-size: 16px;
    }

    & li { 
        margin-bottom: 30px;
	    position: relative;
	    padding-left: 40px;
    }

    & ol {
    	& li {
    		padding-left: 0;
    	}
    }


    & ul {
        position: relative;

        &:before {
        position: absolute;
        content: counter(my-badass-counter) '.';
        counter-increment: my-badass-counter;
        left: 0;
        color: $orange;
        font-size: 35px;
        top: -3px;
        line-height: 1;
        }
 
    }
}

.cols {
    margin: 0 -15px;
	&:after{ 
		@extend .clearfix;
	}
  }

.col { float: left; padding: 0 15px; }
.col_2of3 { width: 60%;}
.col_1of3 { width: 33.33%; }
.col_1of4 { width: 25%; }
.col_1of2 { width: 50%; }
.col_2of2 { 
    width: 100%; 

    &:after {
        @extend .clearfix;
    }
}
.col_type1 { flex: 1 1 41%; }
.col_type2 { flex: 0 1 50%; }
.col_70p { width: 70%; }
.col_30p { width: 30%; }
.col_40p { width: 40%; }
.col_64p { width: 64%; }
.col_36p { width: 36%; }


.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background: $orange; 
    border: none;
}

.alert-danger {
    background: #fbecd4;
    color: #ed8416;
    font-weight: 600;
    border: 1px solid #edd9bb;
}

.alert-success {
    background: #e0fcf2;
    color: #69bea0;
    font-weight: 600;
    border: 1px solid #c6f0e1;
}

@media only screen and (max-width: 767px) {
    .mobile-visible {
        display: block;
    }

	.col {
		float: none;
	}

    .col_1of4,
    .col_1of3,
    .col_1of2,
    .col_64p,
    .col_36p {
		width: 100%;
	}

    .m-col_50p {
        width: 50%;
        float: left;
        padding: 0 10px;
    }
}

@media(max-width: 1530px ){
    .sides .side_plain .side__image {
        width: 100%;
    }
}

@media(max-width: 1150px){
    .sides {
        & .side {
            float: none;
            width: 100%;
            text-align: center;
        }

        & .side__inner {
            min-width: 100%;
            max-width: 100%;
        }

        & .side__rating {
            justify-content: center;
        }

        & .side_plain {
            min-width: 100%;
            max-width: 100%;

            & .side__image,
            & .side__title {
                position: relative;
                left: 0;
                padding: 0 15px;
            }
        }
    }
}

@media(max-width: 767px){
    .mobile_hidden {
        display: none;
    }
    
    .aside {
        float: none;
        width: 100%;
    }


    .flatpickr-calendar {
        width: 400px;
    }

    .flatpickr-day {
        max-width: 58px;
        height: 58px;
        line-height: 58px;
    }

    .flatpickr-rContainer,
    .flatpickr-days {
        width: 100%;
    }

    .dayContainer {
        max-width: 400px;
        min-width: 400px;
        width: 400px;
    }
}


@media(max-width: 479px){
    .flatpickr-calendar {
        width: 300px;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%,-50%);
    }

    .flatpickr-day {
        max-width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .flatpickr-rContainer,
    .flatpickr-days {
        width: 100%;
    }

    .dayContainer {
        max-width: 300px;
        min-width: 300px;
        width: 300px;
    }
}