/* ------------------------------------------------------------ *\
    Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs {
    color: $orange;
    font-size: 18px;
	text-transform: uppercase;

    & a {
        color: inherit;
        
        &:hover {
            color : rgba($yellow, .7);
        }
    }
}